import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TextImage from "../../components/text-image"
import CTA from '../../components/cta'
import Links from '../../components/links'
const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <TextImage
        asHeader
        imgUrl="/home-image.jpg"
        heading="Usługi grzewcze w Brodnicy"
        text='Oferujemy pełen wachlarz usług związanych z ogrzewaniem gospodarstw domowych od doradztwa, naprawy pieców, poprzez korzystne ustawienia sterowników grzewczych czy całkowitą zmianę systemu ogrzewania wraz z planowaniem i montażem nowej kotłowni.<br><br> Twoje ogrzewanie w dobrych rękach!'
      />
      <Links heading='Usługi' posts={posts} />
      <CTA />
    </Layout>
  )
}

export const Head = () => <Seo title="Usługi grzewcze" />

export default Services

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
